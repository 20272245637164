/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-09-23 14:26:15
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-19 11:45:12
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/SynthReport/SynthChart/options.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  tooltip: {
    trigger: 'axis',
    formatter: (params) => {
      let str = ''
      params.forEach((item, index) => {
        const temp = {}
        if (item.seriesName === '媒体消耗' || item.seriesName === '媒体成交eCPM') {
          temp.value = '￥' + item.value
        } else if (
          item.seriesName === '流量填充率' ||
          item.seriesName === '广告填充率' ||
          item.seriesName === '点击率' ||
          item.seriesName === '请求填充率'
        ) {
          temp.value = item.value + '%'
        } else {
          temp.value = item.value
        }
        str += item.marker + item.seriesName + ': ' + temp.value + '<br>'
      })

      return params[0].axisValue + '<br>' + str
    }
  },
  legend: {
    show: true
  },
  grid: {
    top: '5%',
    left: '3%',
    right: '5%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLine: {
      lineStyle: {
        color: '#000',
        width: 0.5
      }
    }
  },
  yAxis: [
    // 0
    {
      type: 'value',
      position: 'left',
      axisLine: {
        lineStyle: {
          color: '#000',
          width: 0.5
        }
      },
      splitLine: {
        show: true
      },

      axisTick: {
        show: true
      },

      axisLabel: {
        show: true
      }
    }
  ],
  series: []
}
